<template>
  <div>
    <div class="invets">
      <div class="invets_left">
        <div class="titles_s">
          <div class="titles_z">
            <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
            <span>GDP地图</span>
          </div>
          <div class="text-right">
            <span>年份 </span>
            <el-select
              v-model="yearValue"
              placeholder="请选择"
              style="width: 100px"
              size="small"
              @change="changeYear"
            >
              <el-option
                v-for="item in yearList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="mt-3">
          <div ref="in_L" class="mt-3" style="width: 100%; height: 450px"></div>
        </div>
      </div>
      <div class="invets_right">
        <div class="titles">
          <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
          相关新闻
        </div>
        <div class="mt-3">
          <el-table
            :data="XW"
            stripe
            :header-cell-style="{ background: '#E9F3FF', color: '#333' }"
            style="width: 100%; font-size: 16px"
          >
            <el-table-column prop="id" label="序号" width="90">
            </el-table-column>
            <el-table-column prop="name" label="标题">
              <template slot-scope="{ row }">
                <span style="color: blue">
                  <a target="_blank" :href="row.url">
                    {{ row.name }}
                  </a>
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="发布时间" width="140">
            </el-table-column>
          </el-table>
          <div class="mt-3 text-right">
            <el-pagination
              @current-change="handleChange"
              :page-size="10"
              layout="total,  prev, pager, next, jumper"
              :total="xwTotal"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="cy">
      <div class="titles">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        国内生产总值
      </div>
      <div
        style="margin-top: 10px; font-size: 16px; color: #333; font-weight: 550"
      >
        历史数据
      </div>
      <div>
        <div class="mt-3">
          <el-button-group>
            <el-button
              size="medium"
              :type="active ? 'primary' : 'pain'"
              @click="gdpBtn(true)"
              >年度GDP</el-button
            >
            <el-button
              size="medium"
              :type="!active ? 'primary' : 'pain'"
              @click="gdpBtn(false)"
              >季度GDP</el-button
            >
          </el-button-group>
        </div>
        <div class="row mt-3">
          <div style="width: 50%">
            <div
              v-show="active"
              ref="in_L1"
              class="mt-3"
              style="width: 100%; height: 350px"
            ></div>
            <div
              v-show="!active"
              ref="in_R1"
              class="mt-3"
              style="width: 100%; height: 350px"
            ></div>
          </div>
          <div style="width: 50%; padding: 0px 0px 0px 15px">
            <el-table
              v-show="active"
              :data="year_gdp_table"
              stripe
              :default-sort="{ prop: 'year', order: 'descending' }"
              :header-cell-style="{ background: '#E9F3FF', color: '#333' }"
              style="width: 100%; font-size: 16px"
            >
              <el-table-column prop="year" label="统计时间"> </el-table-column>
              <el-table-column
                prop="value"
                align="right"
                label="国内生产总值(亿元)"
              >
                <template slot-scope="{ row }">
                  {{ thousandBitSeparator(row.value) }}
                </template>
              </el-table-column>

              <el-table-column
                prop="flag"
                align="right"
                label="国内生产总值增长率(%)"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.flag + "%" }}</span>
                </template>
              </el-table-column>
            </el-table>
            <el-table
              v-show="!active"
              max-height="360"
              :data="quarter_gdp_table"
              :default-sort="{ prop: 'year', order: 'descending' }"
              stripe
              :header-cell-style="{ background: '#E9F3FF', color: '#333' }"
              style="width: 100%; font-size: 16px"
            >
              <el-table-column sortable prop="year" label="统计时间">
              </el-table-column>
              <el-table-column
                prop="value"
                align="right"
                label="国内生产总值(亿元)"
              >
                <template slot-scope="{ row }">
                  {{ thousandBitSeparator(row.value) }}
                </template>
              </el-table-column>

              <el-table-column
                prop="flag"
                align="right"
                label="国内生产总值增长率(%)"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.flag + "%" }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="mt-4">
          <div
            style="
              margin-top: 10px;
              font-size: 16px;
              color: #333;
              font-weight: 550;
            "
          >
            产业分布
          </div>
          <div class="mt-3">
            <div ref="in_L2" style="width: 100%; height: 350px"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="hy">
      <div class="titles">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        国内生产总值构成
      </div>
      <div class="mt-2 row">
        <el-button-group>
          <el-button
            size="medium"
            :type="actives ? 'primary' : 'pain'"
            @click="district(true)"
            >分地区</el-button
          >
          <el-button
            size="medium"
            :type="!actives ? 'primary' : 'pain'"
            @click="district(false)"
            >分行业</el-button
          >
        </el-button-group>
        <div class="row align-items-center ml-3">
          <el-cascader
            v-show="actives"
            ref="cascader"
            size="small"
            @visible-change="companyChange"
            v-model="optionsList"
            :options="options"
            :props="{
              multiple: true,
              checkStrictly: true,
              emitPath: false,
              value: 'label',
            }"
            collapse-tags
            :checkStrictly="true"
            :show-all-levels="false"
          ></el-cascader>
          <el-cascader
            v-show="!actives"
            ref="cascader"
            size="small"
            @visible-change="companyChangehy"
            v-model="optionsListhy"
            :options="optionshy"
            :props="{
              multiple: true,
              checkStrictly: true,
              emitPath: false,
            }"
            collapse-tags
            :checkStrictly="true"
            :show-all-levels="false"
          ></el-cascader>
          <span style="color: #999" class="ml-2"> 最多选择4个 </span>
        </div>
      </div>

      <div
        ref="in_L3"
        v-show="actives"
        class="mt-3"
        style="width: 100%; height: 350px"
      ></div>
      <div
        ref="in_L4"
        v-show="!actives"
        class="mt-3"
        style="width: 100%; height: 350px"
      ></div>
    </div>
    <div class="hy">
      <div class="titles">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        采购经理指数（PMI）
      </div>
      <div ref="in_gc" class="mt-3" style="width: 100%; height: 350px"></div>
    </div>
  </div>
</template>

<script>
import "@/assets/js/china.js";
import {
  map_get_year,
  gdp_data_map,
  news,
  gdp,
  gdp_makeup_get_region,
  gdp_makeup_region,
  gdp_makeup_get_industry,
  gdp_makeup_industry,
  pmi,
} from "@/api.js";

export default {
  data() {
    return {
      yearList: [],
      yearValue: "",

      XW: [],
      page: 1,
      pageSize: 8,
      xwTotal: "",

      active: true,
      year_gdp_table: [],
      quarter_gdp_table: [],

      actives: true,

      maxCount: 4,
      optionsList: ["广东", "江苏", "山东", "浙江"], //类型
      options: [],

      optionsListhy: ["gbA", "gy", "gbK", "gbJ"], //类型
      optionshy: [],
    };
  },
  mounted() {
    this.$loadingShow();
    this.map_get_year_post();

    // this.getChinaMap();
    this.getnews();
    this.getGDP();

    this.getRegion();
    this.getRegionData();

    this.getgdp_makeup_get_industry();

    this.get_in_gc();
  },
  created() {},
  computed: {},
  watch: {
    optionsList(newVal, oldVal) {
      if (newVal.length > 4) {
        this.$message({
          message: "最多只支持选择4项",
          duration: 1500,
          type: "warning",
        });
        this.$nextTick(() => {
          this.optionsList = oldVal;
        });
      }
    },
    optionsListhy(newVal, oldVal) {
      if (newVal.length > 4) {
        this.$message({
          message: "最多只支持选择4项",
          duration: 1500,
          type: "warning",
        });
        this.$nextTick(() => {
          this.optionsListhy = oldVal;
        });
      }
    },
  },
  filters: {},
  methods: {
    changeYear() {
      this.getChinaMap();
    },
    map_get_year_post() {
      this.$loadingShow();
      map_get_year().then(({ data }) => {
        this.yearList = data.year;
        this.yearValue = data.year[0];
        this.getChinaMap();
      });
    },

    getChinaMap() {
      this.$loadingShow();
      gdp_data_map({ year: this.yearValue }).then(({ data }) => {
        let QG = data;
        // 销毁已有的图表实例，避免出现多个图表叠加的情况。
        this.$echarts.init(this.$refs.in_L).dispose();
        let myChart = this.$echarts.init(this.$refs.in_L);
        myChart.setOption({
          // 进行相关配置
          // backgroundColor: "#000", //设置背景色
          tooltip: {
            // 鼠标移到图里面的浮动提示框
            show: true,
            transitionDuration: 0,
            formatter: (item) => {
              return (
                item.name +
                "</br>生产总值：" +
                this.thousandBitSeparator(item.data.value) +
                "亿元"
              );
            },
          },

          visualMap: {
            // 地图标注位置设置和内容设置（图中左下角）
            show: true,
            left: 20,
            bottom: 20,
            showLabel: true, // 显示下面属性pieces中的label
            textStyle: {
              color: "#333",
              fontSize: 14,
            },
            icon: "circle", // 标志框形状设置
            itemWidth: 15,
            itemHeight: 15,
            pieces: [
              //根据数据大小，各省显示不同颜色
              {
                gte: 1,
                lte: 20000,
                label: "2万亿以下",
                color: "#C3E9FE",
              },
              {
                gte: 20000,
                lte: 40000,
                label: "2-4万亿",
                color: "#6FB4FE",
              },
              {
                gte: 40000,
                lte: 60000,
                label: "4-6万亿",
                color: "#00AFE8",
              },
              {
                gte: 60000,
                lte: 80000,
                label: "6-8万亿",
                color: "#3A85FD",
              },
              {
                gte: 80000,
                label: "8万亿以上",
                color: "#0023A4",
              },
            ],
          },
          geo: {
            // 这个是重点 —— 这里设置为地图的阴影
            map: "china", // 表示中国地图
            roam: false, // 禁止拖拽
            label: {
              // 标志信息
              normal: {
                show: false, // 是否显示对应地名
                textStyle: {
                  color: "#2B69C8",
                },
              },
            },
            silent: true, // 禁止hover效果
            zoom: 1.1, // 地图比例
            center: [105.194115019531, 36.3], // 地图中心位置， 此处的纬度与下面的center相差1度是形成阴影的距离，可自己随意调整
            itemStyle: {
              normal: {
                borderColor: "transparent",
                color: "#9E9E9E",
              },
              emphasis: {
                show: false,
              },
            },
          },
          series: [
            // 此处为真正的地图
            {
              name: "风险数", // 浮动框的标题
              type: "map",
              map: "china",
              zoom: 1.1,
              zlevel: 1, // 设置地图的层级，将该地图上移（类似z-index）
              select: {
                itemStyle: {
                  areaColor: "#F7882B",
                  borderColor: "#F7882B",
                  borderWidth: "1",
                },
              },
              // emphasis: {
              //   // 鼠标hover是否显示内容，可自己调节
              //   label: {
              //     show: false,
              //   },
              //   textStyle: {
              //     color: "#C3E9FE",
              //   },
              // },
              label: {
                // 地图标注
                normal: {
                  show: false, // 是否显示对应地名
                  textStyle: {
                    color: "#333",
                  },
                },
              },
              center: [105.194115019531, 35.582111640625], // 设置地图中心
              itemStyle: {
                // 地图的样式
                normal: {
                  borderColor: "#999", // 省份边框颜色
                  borderWidth: 0.5, // 省份边框宽度
                  // shadowColor:"rgba(100,255,238,0.5)", // 省份边框阴影
                  // shadowBlur: 5, // 省份边框聚焦
                },
                emphasis: {
                  // 鼠标hover显示
                  show: false,
                  areaColor: "#F7882B",
                  color: "#333",
                  textStyle: {
                    color: "#333",
                  },
                },
              },
              data: QG,
            },
          ],
        });
        this.$loadingHide();
        window.addEventListener("resize", () => {
          // 地图自适应缩放
          myChart.resize();
        });
      });
    },

    getnews() {
      let param = {
        page: this.page,
        page_size: this.pageSize,
      };
      news(param).then(({ data }) => {
        this.XW = data.results;
        this.xwTotal = data.count;
      });
    },
    handleChange(val) {
      this.page = val;
      this.getnews();
    },

    getGDP() {
      this.$loadingShow();
      gdp().then(({ data }) => {
        this.year_gdp = data.year_gdp;
        this.quarter_gdp = data.quarter_gdp;
        this.industry_distribution = data.industry_distribution;
        let year_gdp_table = data.year_gdp;
        let quarter_gdp_table = data.quarter_gdp;

        this.gdpBtn(true);
        this.get_in_L2();
        for (let i = 0; i < year_gdp_table.year.length; i++) {
          this.year_gdp_table.push({
            year: year_gdp_table.year[i],
            value: year_gdp_table.国内生产总值[i],
            flag: year_gdp_table.国内生产总值增长率[i],
          });
        }
        for (let i = 0; i < quarter_gdp_table.quarter.length; i++) {
          this.quarter_gdp_table.push({
            year: quarter_gdp_table.quarter[i],
            value: quarter_gdp_table.国内生产总值[i],
            flag: quarter_gdp_table.国内生产总值增长率[i],
          });
        }
      });
    },
    thousandBitSeparator(cellValue) {
      let value = cellValue;
      if (value) {
        return Number(value)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
            return $1 + ",";
          })
          .replace(/\.$/, "");
      }
    },
    gdpBtn(val) {
      this.active = val;
      if (val) {
        this.get_in_L1();
      } else {
        setTimeout(() => {
          this.get_in_R1();
        });
      }
    },

    get_in_L1() {
      let month = this.year_gdp.year;
      let data1 = this.year_gdp.国内生产总值;
      let data2 = this.year_gdp.国内生产总值增长率;
      var option = {
        color: ["#529EE2", "#FAD33F"],
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        legend: {},
        xAxis: [
          {
            type: "category",
            data: month,
            // axisPointer: {
            //   type: "shadow",
            // },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位：亿元",
          },
          {
            type: "value",
            min: -30,
            max: 30,
            // interval: 10,
            axisLabel: {
              formatter: "{value}%",
            },
          },
        ],
        series: [
          {
            name: "国内生产总值(亿元)",
            type: "bar",
            barWidth: 50,
            // tooltip: {
            //   valueFormatter: function (value) {
            //     return value;
            //   },
            // },
            data: data1,
          },

          {
            name: "国内生产总值增长率(%)",
            type: "line",
            yAxisIndex: 1,

            // tooltip: {
            //   valueFormatter: function (value) {
            //     return value + "%";
            //   },
            // },
            data: data2,
          },
        ],
      };
      this.$echarts.init(this.$refs.in_L1).setOption(option);
    },
    get_in_R1() {
      let quarter = this.quarter_gdp.quarter;
      let data1 = this.quarter_gdp.国内生产总值;
      let data2 = this.quarter_gdp.国内生产总值增长率;
      var option = {
        color: ["#529EE2", "#FAD33F"],
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "15%",
          containLabel: true,
        },
        legend: {},
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 50,
          },
          {
            start: 0,
            end: 50,
          },
        ],
        xAxis: [
          {
            type: "category",
            data: quarter,
            // axisPointer: {
            //   type: "shadow",
            // },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位：亿元",
          },
          {
            type: "value",
            min: -30,
            max: 30,
            // interval: 10,
            axisLabel: {
              formatter: "{value}%",
            },
          },
        ],
        series: [
          {
            name: "国内生产总值(亿元)",
            type: "bar",
            // tooltip: {
            //   valueFormatter: function (value) {
            //     return value;
            //   },
            // },
            data: data1,
          },

          {
            name: "国内生产总值增长率(%)",
            type: "line",
            yAxisIndex: 1,
            // tooltip: {
            //   valueFormatter: function (value) {
            //     return value + "%";
            //   },
            // },
            data: data2,
          },
        ],
      };
      this.$echarts.init(this.$refs.in_R1).setOption(option);
    },

    get_in_L2() {
      this.$loadingShow();
      let year = this.industry_distribution.year;
      let data1 = this.industry_distribution.第一产业;
      let data2 = this.industry_distribution.第二产业;
      let data3 = this.industry_distribution.第三产业;
      let data4 = this.industry_distribution.国内生产总值;

      var option = {
        color: ["#FAD33F", "#F7882B", "#529EE2", "#F2637B"],
        tooltip: {
          trigger: "axis",
        },
        legend: {},
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
        },

        yAxis: {
          type: "category",
          data: year,
          name: "单位：亿元",
          nameLocation: "end",
        },
        series: [
          {
            name: "第一产业GDP(亿元)",
            type: "bar",
            stack: "total",
            // label: {
            //   show: true,
            // },
            // emphasis: {
            //   focus: "series",
            // },
            data: data1,
          },
          {
            name: "第二产业GDP(亿元)",
            type: "bar",
            stack: "total",
            // label: {
            //   show: true,
            // },
            // emphasis: {
            //   focus: "series",
            // },
            data: data2,
          },
          {
            name: "第三产业GDP(亿元)",
            type: "bar",
            stack: "total",
            // label: {
            //   show: true,
            // },
            // emphasis: {
            //   focus: "series",
            // },
            data: data3,
          },
          {
            name: "国内生产总值",
            type: "line",
            // label: {
            //   show: true,
            // },
            emphasis: {
              focus: "series",
            },
            data: data4,
          },
        ],
      };
      this.$echarts.init(this.$refs.in_L2).setOption(option);
      this.$loadingHide();
    },

    district(val) {
      this.actives = val;
      if (val) {
      } else {
        this.getgdp_makeup_industry();
      }
    },

    companyChange(val) {
      if (val == false) {
        this.getRegionData();
      }
    },
    // 获取地区列表
    getRegion() {
      gdp_makeup_get_region().then(({ data }) => {
        for (let i = 0; i < data.length; i++) {
          this.options.push({
            label: data[i],
          });
        }
      });
    },
    // 获取地区数据
    getRegionData() {
      this.$loadingShow();
      gdp_makeup_region({
        region_list: this.optionsList,
      }).then(({ data }) => {
        this.getChart(data);
      });
    },

    companyChangehy(val) {
      if (val == false) {
        this.getgdp_makeup_industry();
      }
    },
    // 获取行业列表
    getgdp_makeup_get_industry() {
      gdp_makeup_get_industry().then(({ data }) => {
        for (let i in data) {
          this.optionshy.push({
            label: data[i],
            value: i,
          });
        }
      });
    },
    // 获取行业数据
    getgdp_makeup_industry() {
      this.$loadingShow();
      gdp_makeup_industry({
        industry_list: this.optionsListhy,
      }).then(({ data }) => {
        this.getChart1(data);
      });
    },

    getChart(data) {
      let series = [];
      for (let key in data.data) {
        series.push({
          name: key,
          type: "line",
          data: data.data[key],
        });
      }
      this.$echarts.init(this.$refs.in_L3).dispose();
      var option = {
        color: [
          "#529EE2",
          "#F7882B",
          "#00CEDC",
          "#FAD33F",
          "#F2637B",
          "#3EBB9F",
          "#705DF9",
          "#B3E5FF",
        ],
        tooltip: {
          trigger: "axis",
          order: "valueDesc",
        },
        legend: {},
        grid: {
          left: "3%",
          right: "3%",
          bottom: "15%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 50,
          },
          {
            start: 0,
            end: 50,
          },
        ],

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: data.time,
        },
        yAxis: {
          type: "value",
          name: "单位：亿元",
          // axisLabel: {
          //   formatter: "{value} %",
          // },
        },
        series: series,
      };
      this.$echarts.init(this.$refs.in_L3).setOption(option);
      this.$loadingHide();
    },
    getChart1(data) {
      this.$loadingShow();
      let series = [];
      for (let key in data.data) {
        series.push({
          name: key,
          type: "line",
          data: data.data[key],
        });
      }
      this.$echarts.init(this.$refs.in_L4).dispose();
      var option = {
        color: [
          "#529EE2",
          "#F7882B",
          "#00CEDC",
          "#FAD33F",
          "#F2637B",
          "#3EBB9F",
          "#705DF9",
          "#B3E5FF",
        ],
        tooltip: {
          trigger: "axis",
          order: "valueDesc",
        },
        legend: {},
        grid: {
          left: "3%",
          right: "3%",
          bottom: "15%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 50,
          },
          {
            start: 0,
            end: 50,
          },
        ],

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: data.time,
        },
        yAxis: {
          type: "value",
          name: "单位：亿元",
        },
        series: series,
      };
      this.$echarts.init(this.$refs.in_L4).setOption(option);
      this.$loadingHide();
    },

    get_in_gc() {
      pmi().then(({ data }) => {
        let year = data.month;
        let data1 = data["中国综合PMI:产出指数"];
        let data2 = data["制造业PMI"];
        let data3 = data["非制造业PMI:建筑业"];
        let data4 = data["非制造业PMI:服务业"];
        var option = {
          color: ["#FAD33F", "#F7882B", "#529EE2", "#F2637B"],
          legend: {},
          tooltip: {
            trigger: "axis",
            order: "valueDesc",
          },

          xAxis: { type: "category", data: year },
          yAxis: {
            type: "value",
            min: "dataMin",
            max: "dataMax",
            name: "单位：%",
          },
          grid: {
            left: "3%",
            right: "3%",
            bottom: "15%",
            containLabel: true,
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 50,
            },
            {
              start: 0,
              end: 50,
            },
          ],
          series: [
            {
              name: "中国综合PMI:产出指数",
              type: "bar",
              data: data1,
            },
            {
              name: "制造业PMI",
              type: "bar",
              data: data2,
            },
            {
              name: "非制造业PMI:建筑业",
              type: "bar",
              data: data3,
            },
            {
              name: "非制造业PMI:服务业",
              type: "bar",
              data: data4,
            },
          ],
        };
        this.$echarts.init(this.$refs.in_gc).setOption(option);
      });
    },

    sortByDate(obj1, obj2) {
      let val1 = obj1.year;
      let val2 = obj2.year;
      return val2 - val1;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-table .el-table__cell {
  padding: 12px 10px !important;
}
.titles {
  font-size: 20px;
  font-weight: 550;
}
.titles_s {
  display: flex;
  justify-content: space-between;
  .titles_z {
    font-size: 20px;
    font-weight: 550;
    img {
      margin-right: 5px;
    }
  }
}

.invets {
  display: flex;
  justify-content: space-between;
  .invets_left,
  .invets_right {
    width: 49.5%;
    padding: 15px 20px;
    border-radius: 5px;

    background: #fff;
  }
}
.cy,
.hy {
  margin-top: 25px;
  width: 100%;
  padding: 15px 20px;
  border-radius: 5px;

  background: #fff;
}
</style>